<template>
  <header>
    <div class="headcontainer">
      <div :class="{ resolution_test: env !== 'production' }"></div>

      <!-- ***********MOBILE MENU********** -->

      <Slide right class="mobile" :closeOnNavigation="true">
        <!-- <li v-if="show_basvuru">
            <div class="btn" @click="SET_MODAL(whichModalToOpen)">
              <a append><strong>Başvuru</strong></a>
            </div>
        </li>-->

        <!-- <li v-if="show_basvuru">
            <div class="btn"><router-link to="/giris">Giriş Yap</router-link></div>
            <div class="btn second"><router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link></div>
            <div class="">
              <router-link
                to="/hizmet-saglayici/basvuru/devam-giris"
                class="blue"
                >Mevcut Başvuruya Devam Et</router-link
              >
            </div>
        </li>-->

        <div v-show="!aydinlatma" class="topnav" v-if="show_basvuru">
          <ul>
            <!-- <li ><div class="hbtn bir" @click=" SET_MODAL('ModalPreFaz'); SET_NOTIFY_REQUESTER_TYPE(0); "><a>Bireysel Giriş</a></div></li> -->
            <!-- <li class="ayrac">|</li> -->

            <li>
              <div class="hbtn kur">
                <!-- <a href="http://vatandas.iys.org.tr/">Vatandaş Girişi</a> -->
                <a
                  @click.prevent="SET_MODAL('ModalAydinlatmaVatandas')"
                  href="http://vatandas.iys.org.tr/"
                  >Vatandaş Girişi</a
                >
              </div>
            </li>

            <li>
              <div class="hbtn kur vbtn">
                <!-- <a href="http://hs.iys.org.tr/">Hizmet Sağlayıcı Girişi</a> -->
                <a
                  @click.prevent="SET_MODAL('ModalAydinlatmaKurumsal')"
                  href="http://hs.iys.org.tr/"
                  >Hizmet Sağlayıcı Girişi</a
                >
              </div>
            </li>

            <!-- <li>
                <div class="hbtn kur vbtn">
                  <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir"
                    >Hemen Başvur</router-link
                  >
                </div>
              </li>

              <li >
                <div class="hbtn kur vbtn">
                  <router-link
                    to="/hizmet-saglayici/basvuru/devam-giris"
                    >Mevcut Başvuruya Devam Et</router-link
                  >
                </div>
              </li>
 -->
          </ul>
        </div>

        <!-- <hr /> -->
        <nav>
          <ul class="navlinks">
            <li :class="{ nav_active: route == '/iys/nedir' }">
              <router-link to="/iys/nedir">Nedir?</router-link>
            </li>
            <!-- <li :class="{ nav_active: route == '/iys/nasil-calisir' }">
              <router-link to="/iys/nasil-calisir"
                >İleti Yönetim Sistemi Nasıl Çalışır?</router-link
              >
            </li>-->
            <li>
              <span class="spanlink">
                <router-link to="/hizmetlerimiz">Hizmetlerimiz</router-link>
              </span>
              <ul class="altlinks">
                <li :class="{ nav_active: route == '/vatandas-hizmetleri' }">
                  <router-link to="/vatandas-hizmetleri"
                    >Vatandaş Hizmetleri</router-link
                  >
                </li>
                <li
                  :class="{
                    nav_active: route == '/hizmet-saglayici/temel-hizmetler',
                  }"
                >
                  <router-link to="/hizmet-saglayici/temel-hizmetler"
                    >Temel Hizmetler</router-link
                  >
                </li>
                <li
                  :class="{
                    nav_active: route == '/hizmet-saglayici/kurumsal-hizmetler',
                  }"
                >
                  <router-link to="/hizmet-saglayici/kurumsal-hizmetler"
                    >Kurumsal Hizmetler</router-link
                  >
                </li>
                <li
                  :class="{
                    nav_active:
                      route == '/hizmet-saglayici/araci-hizmet-saglayicilar',
                  }"
                >
                  <router-link to="/hizmet-saglayici/araci-hizmet-saglayicilar"
                    >Aracı Hizmet Sağlayıcılar</router-link
                  >
                </li>
              </ul>
            </li>

            <li :class="{ nav_active: route == '/is-ortaklari' }">
              <router-link to="/is-ortaklari">İş Ortakları</router-link>
            </li>
            <li :class="{ nav_active: route == '/iys/sss' }">
              <router-link to="/iys/sss">SSS</router-link>
            </li>
            <li :class="{ nav_active: route == '/iys/duyurular' }">
              <router-link to="/iys/duyurular">Duyurular</router-link>
            </li>
            <li :class="{ nav_active: route == '/iys/hakkimizda' }">
              <router-link to="/iys/hakkimizda">Hakkımızda</router-link>
            </li>
            <!-- <li :class="{ nav_active: route == '/iys/duyurular' }">
                    <router-link to="/iys/duyurular">Duyurular</router-link>
            </li>-->
            <li :class="{ nav_active: route == '/iletisim' }">
              <router-link to="/iletisim">İletişim</router-link>
            </li>
          </ul>
        </nav>

        <div class="sm">
          <div class="logos">
            <!-- <a href="http://fb.me/iletiyonetim" target="_blank">
              <img src="@/assets/img/icons/sm/sm-facebook.svg" alt="facebook" />
              <img
                src="@/assets/img/icons/sm/sm-facebook-o.svg"
                alt="facebook"
                class="sm-img-sec"
              />
            </a> -->
            <!-- <a href="https://twitter.com/iletiyonetim" target="_blank">
              <img src="@/assets/img/icons/sm/sm-twitter.svg" alt="twitter" />
              <img
                src="@/assets/img/icons/sm/sm-twitter-o.svg"
                alt="twitter"
                class="sm-img-sec"
              />
            </a> -->
            <a href="https://twitter.com/iletiyonetim" target="_blank">
              <img src="@/assets/img/icons/sm/sm-x.svg" alt="twitter" />
              <img
                src="@/assets/img/icons/sm/sm-x-o.svg"
                alt="twitter"
                class="sm-img-sec"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/i%CC%87leti-y%C3%B6netim-sistemi"
              target="_blank"
            >
              <img src="@/assets/img/icons/sm/sm-linkedin.svg" alt="linkedin" />
              <img
                src="@/assets/img/icons/sm/sm-linkedin-o.svg"
                alt="linkedin"
                class="sm-img-sec"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCwhKVzK1oKZ2GAJ-rO4FP5A"
              target="_blank"
            >
              <img src="@/assets/img/icons/sm/sm-youtube.svg" alt="youtube" />
              <img
                src="@/assets/img/icons/sm/sm-youtube-o.svg"
                alt="youtube"
                class="sm-img-sec"
              />
            </a>
          </div>
        </div>
        <!-- <div class="mt mobile isocert"><img src="@/assets/img/iso-cert.svg" alt="İleti Yönetim Sistemi ISO" /></div> -->
      </Slide>

      <!-- ***********MOBILE END********** -->

      <!-- ***********DESKTOP START********** -->

      <div id="nav" class="do">
        <div class="wide" ref="navheight">
          <div class="logogroup">
            <div class="infologo">
              <a
                href="https://www.ticaret.gov.tr/"
                target="_blank"
                rel="noopener"
              >
                <img
                  src="@/assets/img/TCTB_Logo.svg"
                  alt="Ticaret Bakanlığı logo"
                />
              </a>
              <a href="https://www.tobb.org.tr/" target="_blank" rel="noopener">
                <img src="@/assets/img/Tobb_Logo.svg" alt="TOBB logo" />
              </a>
            </div>
            <div class="logo">
              <router-link to="/">
                <img
                  src="@/assets/img/iys-logo.svg"
                  alt="İleti Yönetim Sistemi - İleti Yönetim Sistemi - Logo"
                />
              </router-link>
            </div>
            <!-- <div class="logo" style="padding-left:15px; padding-top:5px;">
              <router-link to="/">
                <img
                  width="110px"
                  height="100px"
                  src="@/assets/img/100yil-logo.png"
                  alt="İleti Yönetim Sistemi - 100.yıl - Logo"
                />
              </router-link>
            </div> -->
          </div>

          <!-- ***********NORMAL MENU********** -->
          <div v-show="!aydinlatma" class="normal mainnav">
            <nav>
              <ul>
                <li :class="{ nav_active: route == '/iys/nedir' }">
                  <router-link to="/iys/nedir">Nedir?</router-link>
                </li>

                <!-- <li
                  :class="{ nav_active: route == '/iys/nasil-calisir' }"
                  class="bolder"
                >
                  <router-link to="/iys/nasil-calisir"
                    >Nasıl Çalışır?</router-link
                  >
                </li>-->

                <li
                  :class="{
                    nav_active: route == '/hizmetlerimiz',
                    active_menu: hover,
                  }"
                  @mouseover="hover = true"
                  @mouseleave="hover = false"
                >
                  <div class="altnavmenu">
                    <router-link to="/hizmetlerimiz">Hizmetlerimiz</router-link>
                    <span>
                      <img src="@/assets/img/ico_d_arrow_2.svg" alt />
                    </span>
                  </div>
                </li>
                <!-- <li :class="{ nav_active: route == '/vatandaslar' }">
                  <router-link to="/vatandaslar">Vatandaşlar</router-link>
                </li> -->
                <li :class="{ nav_active: route == '/is-ortaklari' }">
                  <router-link to="/is-ortaklari">İş Ortakları</router-link>
                </li>
                <li :class="{ nav_active: route == '/iys/sss' }">
                  <router-link to="/iys/sss">SSS</router-link>
                </li>
                <li :class="{ nav_active: route == '/iys/duyurular' }">
                  <router-link to="/iys/duyurular">Duyurular</router-link>
                </li>
                <li :class="{ nav_active: route == '/iys/hakkimizda' }">
                  <router-link to="/iys/hakkimizda">Hakkımızda</router-link>
                </li>
                <!-- <li :class="{ nav_active: route == '/iys/duyurular' }">
                  <router-link to="/iys/duyurular">Duyurular</router-link>
                </li>-->
              </ul>
            </nav>
          </div>

          <div v-show="!aydinlatma" class="topnav normal" v-if="show_basvuru">
            <ul class="ulflex">
              <!-- <li ><div class="hbtn bir" @click=" SET_MODAL('ModalPreFaz'); SET_NOTIFY_REQUESTER_TYPE(0); "><a>Bireysel Giriş</a></div></li> -->
              <!-- <li class="ayrac">|</li> -->

              <!-- <li>
                <div class="hbtn bir">
                  <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir"
                    >Hemen Başvur</router-link
                  >
                </div>
              </li> -->
              <li>
                <div class="hbtn kur vbtn">
                  <!-- <a target="_blank" href="http://hs.iys.org.tr/"
                    >Hizmet Sağlayıcı Girişi</a
                  > -->
                  <a
                    @click.prevent="SET_MODAL('ModalAydinlatmaKurumsal')"
                    target="_blank"
                    href="http://hs.iys.org.tr/"
                    >Hizmet Sağlayıcı Girişi</a
                  >
                </div>
              </li>
              <li>
                <div class="hbtn kur">
                  <!-- <a target="_blank" href="http://vatandas.iys.org.tr/"
                    >Vatandaş Girişi</a
                  > -->
                  <a
                    @click.prevent="SET_MODAL('ModalAydinlatmaVatandas')"
                    target="_blank"
                    href="http://vatandas.iys.org.tr/"
                    >Vatandaş Girişi</a
                  >
                </div>
              </li>
            </ul>
          </div>

          <div class="topnav hide" v-else></div>
        </div>
      </div>
    </div>

    <div
      class="active_menu_gen"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ active_menu: onHizmetlerimiz || hover }"
    >
      <div class="altnav">
        <nav>
          <ul>
            <li :class="{ nav_active: route == '/vatandas-hizmetleri' }">
              <router-link to="/vatandas-hizmetleri"
                >Vatandaş Hizmetleri</router-link
              >
            </li>

            <!-- <li :class="{ nav_active: route == '/hizmet-saglayicilar' }">
              <router-link to="/hizmet-saglayicilar">Vatandaş Hizmetleri</router-link>
            </li> -->

            <li
              :class="{
                nav_active: route == '/hizmet-saglayici/temel-hizmetler',
              }"
            >
              <router-link to="/hizmet-saglayici/temel-hizmetler"
                >Temel Hizmetler</router-link
              >
            </li>

            <li
              :class="{
                nav_active: route == '/hizmet-saglayici/kurumsal-hizmetler',
              }"
            >
              <router-link to="/hizmet-saglayici/kurumsal-hizmetler"
                >Kurumsal Hizmetler</router-link
              >
            </li>
            <li
              :class="{
                nav_active:
                  route == '/hizmet-saglayici/araci-hizmet-saglayicilar',
              }"
            >
              <router-link to="/hizmet-saglayici/araci-hizmet-saglayicilar"
                >Aracı Hizmet Sağlayıcılar</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- ***********DESKTOP END********** -->

    <!-- ÇEREZ START -->

    <div class="cerezall" v-if="showCerez">
      <div class="wide">
        <div class="apibody">
          <div class="apiheader">Çerez ve İzleme Aracı Politikası</div>
          <div>
            İnternet sitemizin işletimi sırasında çerez ve benzeri teknolojiler
            kullanılmaktadır. Kullanılan çerezler internet sitesinin düzgün
            çalışması ve sitenin işlevselliğinin sağlanması bakımından teknik
            olarak zorunlu niteliktedir. Bu amaçlar dışında sitemizde çerez
            kullanılmamaktadır. Çerezler hakkında detaylı bilgi almak için Çerez
            Politikası Metnini incelemenizi rica ederiz.
          </div>

          <div class="apilink">
            <div class="btn third">
              <router-link to="/iys/cerez-ve-izleme-araci-politikasi"
                >Çerez ve İzleme Aracı Politikası
                <img class="go blue" src="@/assets/img/ico_detail.svg" alt=""
              /></router-link>
            </div>

            <div class="btn half">
              <a @click="CEREZ_KABUL" class="flex">Okudum, Anladım</a>
            </div>
          </div>
        </div>
        <!-- <div class="apiclose">
          <div class="">
            <a @click="HIDE_DUYURU"><img
                src="@/assets/img/ico_close.svg"
                alt=""
              /></a>
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="apiduyuru" v-if="showDuyuru">
      <div class="wide">
        <div class="apibody">
          <div class="apiheader">DUYURU</div>
          <div>
            Artık İleti Yönetim Sistemi’ni kullanmak daha kolay! <br />
            <b>1 Aralık 2020</b> tarihine kadar başvurusunu tamamlayan tüm
            hizmet sağlayıcılara <b>İLETİ 5</b>, <b>İLETİ 25</b> ve
            <b>İLETİ 75</b>, Seviye – 1 hizmetleri bir yıl süreyle ücretsiz.
          </div>

          <div class="btn fourth">
            <router-link to="/iletisim" class="flex">Bize ulaşın <img class="go" src="@/assets/img/ico_detail.svg" alt="" /></router-link>
            <router-link to="/hizmet-saglayici/paketler-ve-moduller"
              >Detaylı bilgi</router-link
            >
          </div>
        </div>

        <div class="apiclose">
          <div class="">
            <a @click="HIDE_DUYURU"
              ><img src="@/assets/img/ico_close.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="apiduyuru"  v-if="showDuyuru" >
      <div class="apiheader">DUYURU</div>
      <div class="apibody">
        Hizmet Sağlayıcılar için İleti Yönetim Sistemi hizmetleri ve fiyatları açıklanmıştır.
      </div>
      <div class="apilink">
        <div class="btn second">
          <router-link to="/hizmet-saglayici/hizmetler-ve-paketler">Paketleri incele</router-link>
        </div>
      </div>
      <div class="apiclose">
        <div class="btn third">
          <a @click="HIDE_DUYURU" ><img src="@/assets/img/ico_close.svg" alt="" /></a>
        </div>
      </div>
    </div> -->
  </header>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { log } from "util";
import { Slide } from "vue-burger-menu"; // import the CSS transitions you wish to use, in this case we are using `Slide`

const duyuru_name = "cerez-politikasi";

export default {
  name: "Header",
  components: {
    Slide, // Register your component
  },
  data() {
    return {
      // whichModalToOpen : "ModalPreFaz",
      whichModalToOpen: "ModalBasvuru",
      route: "",
      env: process.env.VUE_APP_ENV,
      hover: false,
      showCerez: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.route = to.path;
      },
    },
  },

  created() {
    let showCerez = true;
    if (window.localStorage) {
      if (localStorage.getItem(duyuru_name)) {
        showCerez = false;
      }
      // localStorage.setItem(duyuru_name, "OK");
    } else if (window.sessionStorage) {
      if (sessionStorage.getItem(duyuru_name)) {
        showCerez = false;
      }
      // sessionStorage.setItem(duyuru_name, "OK");
    }

    if (showCerez) {
      this.showCerez = true;
    }
  },

  computed: {
    ...mapState("app", ["show_basvuru", "showDuyuru"]),
    aydinlatma() {
      return this.$route.meta.aydinlatma;
    },
    onHizmetlerimiz() {
      return [
        "/vatandas-hizmetleri",
        "/hizmet-saglayici/temel-hizmetler",
        "/hizmet-saglayici/kurumsal-hizmetler",
        "/hizmet-saglayici/araci-hizmet-saglayicilar",
      ].includes(this.$route.path);
    },
  },
  methods: {
    ...mapMutations("app", ["SET_MODAL", "HIDE_DUYURU"]),
    CEREZ_KABUL() {
      if (window.localStorage) {
        localStorage.setItem(duyuru_name, "OK");
      } else if (window.sessionStorage) {
        sessionStorage.setItem(duyuru_name, "OK");
      }
      this.showCerez = false;
    },
    API_BASVURU_FORMU_CLICK() {
      this.$router.push("/api-basvuru-formu");
      this.HIDE_DUYURU();
    },
  },
};
</script>

<style scoped>
.isocert {
  padding-left: 30px !important;
}

.altnavmenu {
  cursor: pointer;
}

.altnavmenu a {
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: none !important;
}
nav .altnavmenu span img {
  width: 16px;
}
nav .altnavmenu span {
  transform: rotate(0deg);
  display: flex;
  transition: all 0.3s ease-in;
  margin: 0 0 0 5px;
}
nav .altnavmenu:hover span,
.active_menu span {
  transform: rotate(180deg);
}

header {
  position: fixed;
  z-index: 9;
}

.active_menu_gen {
  visibility: hidden;
  display: flex;
  opacity: 0;
  background: #fff;
  width: 100%;
  z-index: 99;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s 0.2s ease-in;
  box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.1);
  top: -100px;
}

.active_menu {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in !important;
  top: 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  position: relative;
  z-index: 999;
}
nav .logo {
  width: 6vw;
}
nav ul {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
nav ul li > * {
  margin: 0;
}
nav ul li {
  margin: 0;
}
nav ul li.bolder a {
  font-weight: 600;
}

#nav {
  padding: 0;
}
#nav .wide {
  max-width: 1400px;
  padding: 0 2vw;
}

nav a,
nav .spanlink,
nav .altnavmenu {
  color: #4da0df !important;
  font-weight: 400;
  border-bottom: 3px #fff0 solid;
  padding: 30px 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

nav a:hover,
nav .altnavmenu:hover {
  background: #fdfbfa;
  /* border-bottom: 3px #4da0df solid; */
}

.nav_active a {
  border-bottom: 3px #4da0df solid;
}

.altnav nav a {
  color: #55565e !important;
  font-weight: 600;
  transition: all 0.3s ease-in;
  border-bottom: 3px #5550 solid;
  padding: 1.4vh 20px;
  opacity: 0.7;
}

.altnav nav .nav_active a {
  border-bottom: 3px #55565e solid;
}

.altnav nav a:hover {
  /* border-bottom: 3px #fff solid; */
  opacity: 1;
  color: #000 !important;
  background: #fff;
}

.active_menu a {
  border-bottom: 3px rgba(255, 255, 255, 0) solid;
}

/* .active_menu .altnav {animation: entrance 0.6s ease-in-out 0s ;}

@keyframes entrance {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
} */

.mobile nav {
  padding: 0 !important;
  margin: 0;
}
.mobile .navlinks {
  border-top: 1px solid #e0e9f1;
  border-bottom: 1px solid #e0e9f1;
  margin: 10px 0;
  padding: 10px 0px;
}
.mobile nav a:hover,
.mobile nav .nav_active a {
  background: #fff;
  padding-left: 10px;
}

.mobile nav .hbtn.kur a {
  background: #4da0df;
}

.mobile .mobilekurgroup {
  padding: 5px;
  border: 1px solid #e0e9f1;
  margin: 5px -5px 5px 5px;
  max-width: 100%;
  border-radius: 20px;
}

.mobile nav .kur.vbtn a {
  background: #fdfbfa !important;
  color: #55565e !important;
}

.mobile .navlinks span {
  color: #55565e;
  font-weight: 600;
}
.mobile .altlinks li a {
  color: #55565e !important;
  margin-left: 10px !important ;
}
.mobile .altlinks li {
  margin: 0;
}

.mobile .nav_active a {
  border-bottom: 3px #fff solid !important;
}
.mobile .nav_active a:hover {
  border-bottom: 3px #fff solid !important;
}

.logogroup {
  display: flex;
  align-items: center;
}

header .infologo {
  display: flex;
  justify-content: flex-start;
  margin: 0.5vw 15px 0.5vw 0px;
  border-right: 1px solid #dfdfdf;
}
header .infologo a {
  align-items: center;
  display: flex;
  justify-content: center;
}
header .infologo img {
  width: 100%;
  height: 100%;
  max-width: 60px;
  margin-right: 15px;
}

.stick {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 20px);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.7s ease;
}

.stick > div {
  max-width: 1150px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.stick .logo,
.stick nav {
  transition: all 0.7s ease;
}

.do {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 20px);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
  transition: all 0.7s ease;
  background: rgba(255, 255, 255, 0);
}

.do > div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1150px;
  position: relative;
  top: 0;
  margin: 0 auto;
  z-index: 99;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  background: rgba(232, 232, 232, 0);
  transition: all 0.7s ease;
}

.do .logo,
.do nav {
  /* transform: scale(01); */
  transition: all 0.7s ease;
}

.mainnav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  position: relative;
  z-index: 999;
  height: max-content;
}

.topnav {
  display: flex;
  justify-content: flex-end;
}
.topnav.hide {
  width: 20%;
}

.hbtn {
  background: #4da0df;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5vw;
  overflow: hidden;
}

.vbtn {
  background: #fdfbfa;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5vw;
}

.hbtn,
.hbtn:hover,
.vbtn,
.vbtn:hover {
  transition: all 0.3s ease-in;
}

.hbtn a {
  display: flex;
  flex-flow: wrap;
  border: none;
  text-align: center;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 14px;
  margin: 0;
  font-weight: 600;
  text-decoration: none !important;
  user-select: none;
}

.hbtn:hover {
  background: #0f66a9;
}
.vbtn a {
  color: #55565e;
}
.vbtn:hover {
  background: #f0e2db;
}

.ulflex {
  display: flex;
  justify-content: flex-end;
}

.bir {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px #4da0df inset;
}
.bir a {
  color: #4da0df;
}
.bir:hover {
  background: #fff;
}

.ayrac {
  padding: 0 0.9vw;
}

.kur {
  border-radius: 10px;
}
.bsv {
  border-radius: 10px;
  border-left: 1px solid #fff;
}
</style>
