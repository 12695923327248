<template>
  <div>
    <Header />

    <transition name="fade">
      <router-view name="modal"></router-view>
    </transition>

    <transition name="fade">
      <component :is="modalName"></component>
    </transition>

    <router-view />

    <Footer />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Header from "./Header";
import Footer from "./Footer.vue";

// import ModalBase from "../components/modals/ModalBase";
import ModalPreFaz from "../components/modals/ModalPreFaz";
import ModalVideoVatandas from "../components/modals/ModalVideoVatandas";
import ModalVideoGenel from "../components/modals/ModalVideoGenel";
import ModalVideoKurumsal from "../components/modals/ModalVideoKurumsal";
import ModalFiyat from "../components/modals/ModalFiyat";
import ModalTaahhutname from "../components/modals/ModalTaahhutname";
import ModalTaahhutnameNoMersis from "../components/modals/ModalTaahhutnameNoMersis";
import ModalNoMersis from "../components/modals/ModalNoMersis";
import ModalBasvuru from "../components/modals/ModalBasvuru";
import ModalHizmetSozlesmesi from "../components/modals/ModalHizmetSozlesmesi";
import ModalKanun from "../components/modals/ModalKanun";
import ModalYonetmelik from "../components/modals/ModalYonetmelik";
import ModalVideo from "../components/modals/ModalVideo";
import ModalIletiYonetimi1 from "../components/modals/ModalIletiYonetimi1";
import ModalIletiYonetimi2 from "../components/modals/ModalIletiYonetimi2";
import ModalIletiYonetimi3 from "../components/modals/ModalIletiYonetimi3";
import ModalTarih from "../components/modals/ModalTarih";
import ModalDuyuruImg from "../components/modals/ModalDuyuruImg";
import ModalAydinlatmaKurumsal from "../components/modals/ModalAydinlatmaKurumsal";
import ModalAydinlatmaVatandas from "../components/modals/ModalAydinlatmaVatandas";
import ModalAydinlatmaIletisim from "../components/modals/ModalAydinlatmaIletisim";
import ModalCumhuriyet from "../components/modals/ModalCumhuriyet";

export default {
  name: "MyLayout",
  mounted() {
    this.$root.$on("close_modal", () => this.CLOSE_MODAL());
  },
  components: {
    Header,
    Footer,
    // ModalBase,
    ModalHizmetSozlesmesi,
    ModalTaahhutname,
    ModalTaahhutnameNoMersis,
    ModalNoMersis,
    ModalPreFaz,
    ModalVideoVatandas,
    ModalVideoGenel,
    ModalVideoKurumsal,
    ModalFiyat,
    ModalBasvuru,
    ModalKanun,
    ModalVideo,
    ModalYonetmelik,
    ModalIletiYonetimi1,
    ModalIletiYonetimi2,
    ModalIletiYonetimi3,
    ModalTarih,
    ModalDuyuruImg,
    ModalAydinlatmaKurumsal,
    ModalAydinlatmaVatandas,
    ModalAydinlatmaIletisim,
    ModalCumhuriyet
  },
  computed: {
    ...mapState("app", ["modalName"])
  },
  methods: {
    ...mapMutations("app", ["CLOSE_MODAL"])
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
